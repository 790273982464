<!-- 德育管理/班级管理/年级量化 -->
<template>
  <div>
    <div v-if="!errorShow" class="right-panel">
        <loading :loadingShow="loadingShow"></loading>
        <div v-if="!loadingShow" class="content-main">
            <!-- 头部筛选 -->
            <div class="filter-wrap">
                <expand-filter
                    :closeWidth="formData.closeWidth"
                    :formData="formDataComputed"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    margin-bottom="0">
                    <template #datePicker="{slotData}">
                        <div class="date-picker-wrap">
                            <el-date-picker
                                style="width:330px;"
                                v-model="slotData.value"
                                :clearable="false"
                                type="daterange"
                                start-placeholder="请选择开始时间"
                                end-placeholder="请选择结束时间"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </div>
                    </template>
                </expand-filter>
                <div class="button-line"></div>
                <div class="filter_Btn" >
                    <el-button
                        v-has-permi="[
                                    'moralmanage:classmanage:gradedata:export',
                                ]"
                        :loading="exportLoading"
                        type="primary"
                        @click="handleExport()"
                        >下载统计</el-button
                    >
                </div>
            </div>
            <!-- 列表 -->
            <table-data
                ref="table"
                id="table"
                v-loading="loadingTable"
                :config="table_config"
                :tableData="table_data"
                :header-cell-style="{}"
                @handleSortChange="handleSortChange"
            >
                <template v-slot:nameHeader="slotData">
                    <div class="name-header-slot">
                        <el-button
                            type="text"
                            @click="handleClick"
                            class="expand-all"
                            :class="{active: table_config.isExpandAll}"
                        >
                            <i class="el-icon-arrow-down" />
                        </el-button>
                        <span>组织名称</span>
                    </div>
                </template>
                <template v-slot:nameContent="slotData">
                    <el-button
                        type="text"
                        @click="linkClick(slotData.data)"
                        :disabled="isDisabled"
                    >
                        {{ slotData.data.organName }}
                    </el-button>
                </template>
            </table-data>
            <!-- 德育考核记录 -->
            <dialog-wrapper
                :dialogObj="dialogObj"
                @handleClose="handleClose"
            >
                <div class="top_row">
                    <div class="score_img">
                        <img
                            :src="exportUrl"
                            alt=""
                            style="width: 14px; height: 14px"
                        />
                    </div>
                    <div class="all_score">总计：</div>
                    <div
                        :class="stuTotal < 0 ? 'add_score' : 'reduce_score'"
                    >
                        {{ stuTotal }}
                    </div>
                    <div class="score_img">
                        <img
                            :src="addUrl"
                            alt=""
                            style="width: 14px; height: 14px"
                        />
                    </div>
                    <div class="all_score">表扬：</div>
                    <div
                        :class="totalScoreHonour < 0 ? 'add_score' : 'reduce_score'"
                    >
                        {{ totalScoreHonour }}
                    </div>
                    <div class="score_img">
                        <img
                            :src="reduceUrl"
                            alt=""
                            style="width: 14px; height: 14px"
                        />
                    </div>
                    <div class="all_score">批评：</div>
                    <div
                        :class="totalScorePunish < 0 ? 'add_score' : 'reduce_score'"
                    >
                        {{ totalScorePunish }}
                    </div>
                </div>
                <el-table
                    height="400"
                    :data="dialogTableList"
                    v-loading="stuLoadingTable"
                    border
                    style="width: 100%"
                    :header-cell-style="{
                        background: '#FAFBFC',
                    }"
                    :row-style="{height: '70px'}">
                    <el-table-column
                        prop="className"
                        label="班级"
                        width="190"
                    ></el-table-column>
                    <el-table-column
                        prop="studentName"
                        label="学生"
                    ></el-table-column>
                    <el-table-column
                        prop="behaviorName"
                        label="考核行为"
                        width="120"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="score"
                        label="分数"
                        align="left"
                    >
                        <template slot-scope="{row}">
                            <span
                                :class="
                                    row.ruleType === '1' ? 'add' : 'jian'
                                "
                                >{{
                                    (row.ruleType === "1" ? "-" : "+") +
                                    row.score
                                }}</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="img"
                            label="凭证"
                        >
                        <template slot-scope="{row}">
                            <div v-for="(item,index) in row.img" :key="index">
                                <video
                                    v-if="(item.toLowerCase().indexOf('mp4') > -1 || item.toLowerCase().indexOf('mov') > -1)"
                                    :class="item ? 'img2' : ''"
                                    @click="handlePreview(item)"
                                    disablePictureInPicture
                                >
                                    <source :src="item">
                                </video>
                                <img
                                    v-else
                                    :src="item"
                                    alt=""
                                    :class="item ? 'img2' : ''"
                                    @click="handleImg(item)"
                                />
                                <!-- <span v-else>-</span> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注"
                        width="120"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        v-if="moralmanageClassManageClassDataCheckPerson"
                        prop="createByName"
                        label="检查人"
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="assessDate"
                        label="考核时间"
                        width="140"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100"
                    >
                        <template slot-scope="{row}">
                            <div class="disFlex">
                                <div
                                    class="btn_edit"
                                    @click="handleEdit(row)"
                                    v-hasPermi="[
                                        'moralmanage:classmanage:gradedata:editHandle',
                                    ]"
                                >
                                    编辑
                                </div>
                                <div
                                    class="btn_delet"
                                    @click="handleDelete(row)"
                                    v-hasPermi="[
                                        'moralmanage:classmanage:gradedata:delete',
                                    ]"
                                >
                                    删除
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 20px">
                    <xk-button @click="handleClose">取消</xk-button>
                    <xk-button
                        v-has-permi="[
                            'moralmanage:classmanage:gradedata:export',
                        ]"
                        @click="handleSubmit"
                        type="primary"
                        >下载详情</xk-button
                    >
                </div>
            </dialog-wrapper>
            <!-- 点击查看图片 -->
            <el-image-viewer
                style="z-index: 9999"
                v-if="showViewer"
                :on-close="handleCloseImg"
                :url-list="[viewPhoto]"
            />
            <!-- 宿舍的考核弹窗 -->
                <dialog-wrapper
                    :dialog-obj="dialogObjDormitory"
                    @handleClose="handleCloseDormitory"
                    class="simple-dialog-form"
                >
                    <div class="common-form">
                        <el-form
                            ref="formRef"
                            :model="ruleFormDormitory"
                            label-width="120px"
                            label-position="right"
                            :rules="rulesDormitory"
                        >
                            <el-form-item
                                label="考核日期"
                                prop="assessDate"
                            >
                                <el-date-picker
                                    v-model="ruleFormDormitory.assessDate"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    clearable
                                    placeholder="请选择考核日期"
                                    class="input-width-medium"
                                />
                            </el-form-item>
                            <el-form-item
                                label="考核类型"
                                prop="ruleType"
                            >
                                <el-radio
                                    v-model="ruleFormDormitory.ruleType"
                                    label="1"
                                    @change="changeDormiType"
                                >
                                    违纪
                                </el-radio>
                                <el-radio
                                    v-model="ruleFormDormitory.ruleType"
                                    label="2"
                                    @change="changeDormiType"
                                >
                                    荣誉
                                </el-radio>
                            </el-form-item>
                            <el-form-item
                                label="考核指标"
                                prop="ruleId"
                            >
                                <el-select-tree
                                    v-model="ruleFormDormitory.ruleId"
                                    class="input-width-medium"
                                    :data="
                                        treeFilter(
                                            treeOptions,
                                            'ruleType',
                                            ruleFormDormitory.ruleType,
                                        )
                                    "
                                    :props="{
                                        value: 'id',
                                        label: 'ruleName',
                                        children: 'children',
                                    }"
                                    clearable
                                    filterable
                                    @change="changeRule"
                                >
                                </el-select-tree>
                            </el-form-item>
                            <el-form-item
                                label="考核行为"
                                prop="behaviorId"
                            >
                                <el-select
                                    v-model="ruleFormDormitory.behaviorId"
                                    :popper-append-to-body="false"
                                    clearable
                                    filterable
                                    :title="' '"
                                    @change="changeBehavior"
                                    class="input-width-medium behavior-select"
                                >
                                    <el-option
                                        v-for="(item, index) in behaviorList"
                                        :label="item.ruleName"
                                        :value="item.id"
                                        :key="index"
                                        :title="item.ruleName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="上传凭证"
                                prop="img"
                            >
                            <div style="display: flex;">
                                <el-upload
                                    :action="action"
                                    :headers="headers"
                                    :multiple="false"
                                    :file-list="fileList"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :accept="acceptValue"
                                    :show-file-list="false"
                                    :limit="3"
                                    :data="{
                                        schoolId: schoolId,
                                        mode: 'moral',
                                    }"
                                    :on-exceed="handleExceed"
                                    class="avatar-uploader"
                                >
                                    <div
                                        v-if="isUpdataImg && isUpdataVideo"
                                        class="upload-wrap"
                                    >
                                        <div class="upload-btn">
                                            <div class="add-icon">+</div>
                                            <div class="upload-text">
                                                上传
                                            </div>
                                        </div>
                                    </div>
                                </el-upload>
                                <div
                                    v-if="ruleFormDormitory.img.length > 0"
                                    class="swiper-wrap"
                                >
                                    <div
                                        v-for="(
                                            item, index
                                        ) in ruleFormDormitory.img"
                                        :key="index"
                                        class="img-wrap"
                                    >
                                        <div class="img_each">
                                            <video
                                                v-if="(item.toLowerCase().indexOf('mp4') > -1 || item.toLowerCase().indexOf('mov') > -1)"
                                                style="width: 80px;height: 80px;border: 1px solid #c0ccda;"
                                                :src="item"
                                                >
                                                <source :src="item">
                                            </video>
                                            <img
                                                v-else
                                                style="
                                                    width: 80px;
                                                    height: 80px;
                                                    border: 1px solid #c0ccda;
                                                "
                                                :src="item"
                                                alt=""
                                            />
                                            <div class="del">
                                                <i
                                                    class="el-icon-delete"
                                                    @click.stop="
                                                        deleteSwiperImg(index)
                                                    "
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="tipText">支持上传视频和照片，视频要求短于20s，小于100M。</span>
                            </el-form-item>

                            <el-form-item
                                :label="
                                    ruleFormDormitory.ruleType === '1'
                                        ? '扣分分值'
                                        : '加分分值'
                                "
                                prop="score"
                            >
                                <el-input
                                    class="input-width-medium"
                                    v-model="ruleFormDormitory.score"
                                    readonly
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="考核说明"
                                prop="remark"
                            >
                                <el-input
                                    v-model="ruleFormDormitory.remark"
                                    clearable
                                    rows="5"
                                    type="textarea"
                                    maxlength="100"
                                    show-word-limit
                                    placeholder="请输入考核说明"
                                    class="input-width-medium"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="footer-button-group">
                            <xk-button @click="handleCloseDormitory">取消</xk-button>
                            <xk-button
                                type="primary"
                                @click="handleSave"
                            >
                                确定
                            </xk-button>
                        </div>
                    </div>
                </dialog-wrapper>
            <!-- 德育考核弹窗 -->
            <dialog-wrapper
                :dialog-obj="dialogObjEduMoral"
                @handleClose="handleCloseEduMoral"
                class="simple-dialog-form"
            >
                <div class="common-form">
                    <el-form
                        ref="formRefEduMoral"
                        :model="ruleFormEduMoral"
                        label-width="120px"
                        label-position="right"
                        :rules="rulesEduMoral"
                    >
                        <el-form-item
                            label="考核日期"
                            prop="assessDate"
                        >
                            <el-date-picker
                                v-model="ruleFormEduMoral.assessDate"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                clearable
                                placeholder="请选择考核日期"
                                class="input-width-medium"
                            />
                        </el-form-item>
                        <el-form-item
                                label="考核类型"
                                prop="ruleType"
                            >
                                <el-radio
                                    v-model="ruleFormEduMoral.ruleType"
                                    label="1"
                                    @change="changeRuleType"
                                >
                                    违纪
                                </el-radio>
                                <el-radio
                                    v-model="ruleFormEduMoral.ruleType"
                                    label="2"
                                    @change="changeRuleType"
                                >
                                    荣誉
                                </el-radio>
                            </el-form-item>
                        <el-form-item
                            label="考核指标"
                            prop="ruleId"
                        >
                            <el-select-tree
                                v-model="ruleFormEduMoral.ruleId"
                                :data="
                                treeFilter(
                                    ruleListEduMoral,
                                    'ruleType',
                                    ruleFormEduMoral.ruleType,
                                )
                            "
                                :props="{
                                    value: 'id',
                                    label: 'ruleName',
                                    children: 'children',
                                }"
                                clearable
                                filterable
                                @change="changeRuleEduMoral"
                                class="input-width-medium"
                            >
                            </el-select-tree>
                        </el-form-item>
                        <el-form-item
                            label="考核行为"
                            prop="behaviorId"
                        >
                            <el-select
                                v-model="ruleFormEduMoral.behaviorId"
                                :popper-append-to-body="false"
                                clearable
                                filterable
                                :title="' '"
                                @change="changeBehaviorEduMoral"
                                class="input-width-medium behavior-select"
                            >
                                <el-option
                                    v-for="(
                                        item, index
                                    ) in behaviorListEduMoral"
                                    :label="item.ruleName"
                                    :value="item.id"
                                    :key="index"
                                    :title="item.ruleName"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="上传凭证"
                            prop="img"
                        >
                        <div style="display: flex;">
                            <el-upload
                                :action="action"
                                :headers="headers"
                                :multiple="false"
                                :file-list="fileLists"
                                :on-success="handleSuccessEduMoral"
                                :before-upload="beforeUploadEduMoral"
                                :accept="acceptValue"
                                :show-file-list="false"
                                :limit="3"
                                :data="{
                                        schoolId: schoolId,
                                        mode: 'moral',
                                    }"
                                :on-exceed="handleExceed"
                                class="avatar-uploader"
                            >
                                <div
                                    v-if="isUpdataImg && isUpdataVideo"
                                    class="upload-wrap"
                                >
                                    <div class="upload-btn">
                                        <div class="add-icon">+</div>
                                        <div class="upload-text">
                                            上传
                                        </div>
                                    </div>
                                </div>
                            </el-upload>
                            <div
                                v-if="ruleFormEduMoral.img.length > 0"
                                class="swiper-wrap"
                            >
                                <div
                                    v-for="(
                                        item, index
                                    ) in ruleFormEduMoral.img"
                                    :key="index"
                                    class="img-wrap"
                                >
                                    <div class="img_each">
                                        <video
                                            v-if="item.toLowerCase().indexOf('mp4') > -1 || item.toLowerCase().indexOf('mov') > -1"
                                            style="width: 80px;height: 80px;border: 1px solid #c0ccda;"
                                             >
                                            <source :src="item">
                                        </video>
                                        <img
                                            v-else
                                            style="
                                                width: 80px;
                                                height: 80px;
                                                border: 1px solid #c0ccda;
                                            "
                                            :src="item"
                                            alt=""
                                        />
                                        <div class="del">
                                            <i
                                                class="el-icon-delete"
                                                @click.stop="
                                                    deleteSwiperImgEduMoral(
                                                        index,
                                                    )
                                                "
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="tipText">支持上传视频和照片，视频要求短于20s，小于100M。</span>
                        </el-form-item>
                        <el-form-item
                            :label="
                                ruleFormEduMoral.ruleType === '1'
                                    ? '扣分分值'
                                    : '加分分值'
                            "
                            prop="score"
                        >
                            <el-input
                                class="input-width-medium"
                                v-model="ruleFormEduMoral.score"
                                readonly
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="考核说明"
                            prop="remark"
                        >
                            <el-input
                                v-model="ruleFormEduMoral.remark"
                                clearable
                                rows="5"
                                type="textarea"
                                maxlength="100"
                                show-word-limit
                                placeholder="请输入考核说明"
                                class="input-width-medium"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="footer-button-group">
                        <xk-button @click="handleCloseEduMoral"
                            >取消</xk-button
                        >
                        <xk-button
                            type="primary"
                            @click="handleSaveEduMoral"
                        >
                            确定
                        </xk-button>
                    </div>
                </div>
            </dialog-wrapper>
             <!-- mov视频预览 -->
            <dialog-wrapper
                :dialog-obj="dialogMov"
                @handleClose="handleCloseVideo"
                class="dialog-form-video"
            >
                <video v-if="videoMovSrc" ref="videoElement" autoplay style="width: 100%;height: 100%;" controls disablePictureInPicture>
                    <source :src="videoMovSrc">
                </video>
            </dialog-wrapper>
        </div>
    </div>
  </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    debounce,
} from "common-local";
import {mapState} from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {SManagerEduDeormitoryGradeDataModel} from '@/models/SManagerEduDormitoryAssessmentGradeData';
import {hasPermission, downloadFile, formatTreeData, treeFind} from "@/libs/utils.js";
import ExpandFilter from "./Sub/ExpandFilter.vue";
import onResize from "@/mixins/onResize";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {getToken} from "@/libs/auth";
import ElSelectTree from "el-select-tree";
import {SManagerEduDeormitoryDataModule} from "@/models/SManagerEduDormitoryAssessmentData.js";
export default {
    name: 'SManagerEduMoralGradeData',
    mixins: [onResize],
    components: {
        DialogWrapper,
        ExpandFilter,
        TableData,
        // 功能组件
        Error,
        Loading,
        Pagination,
        ElImageViewer,
        ElSelectTree
    },
    data(){
        return{
            listQuery: {
                schoolId: "",
                assessDateStart: "",
                assessDateEnd: "",
                organId: [],
                dataScope: "",
            },
            detailQuery: {
                assessDateStart: "",
                assessDateEnd: "",
            },
            subjects: [],
            total: 0,
            dialogMov: {
                title: "视频预览",
                dialogVisible: false,
                width: "520px",
            },
            videoMovSrc: "",
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "cascader",
                        label: "",
                        value: "",
                        placeholder: "请选择组织",
                        key: "organId",
                        cascaderProps: {
                            label: 'name',
                            value: 'id',
                            emitPath: false,
                            multiple: true,
                            checkStrictly: true
                        },
                        filter(data) {
                            return data.organType !== '1';
                        },
                        list: [],
                    },
                    {
                        type: "slot",
                        slotName: "datePicker",
                        key: "dateArr",
                        value: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["moralmanage:classmanage:gradedata:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["moralmanage:classmanage:gradedata:list"],
                    },
                ],
                btnFormType: true,
                closeWidth: 530,
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        type: 'setHeader',
                        slotName: 'nameHeader',
                        slotName1: 'nameContent',
                        prop: "organName",
                        label: "组织名称",
                        prop: "name",
                        align: "left",
                        width: "300",
                        fixed: false
                    },
                    {
                        label: "班主任姓名",
                        prop: "masterName",
                        labelWidth: "130",
                        type: "tooltipColumn",
                        // labelWidth: "85px",
                        splitSign: ",",
                        align: 'center'
                    },
                    {
                        label: "总分",
                        prop: "totalScore",
                        sortable: "custom",
                        minWidth: 120,
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            if(row.totalScore == null){
                                return `-`
                            } else {
                                if (row.totalScore >= 0) {
                                    return `<div style="color:#71CE66;overflow: hidden;text-overflow: ellipsis">${row.totalScore}</div>`;
                                } else if (row.totalScore < 0) {
                                    return `<div style="color:#F99D5E;overflow: hidden;text-overflow: ellipsis">${row.totalScore}</div>`;
                                } else {
                                    return "-";
                                }
                            }
                        },
                    },
                    {
                        label: "表扬",
                        prop: "praiseScore",
                        sortable: "custom",
                        minWidth: 120,
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            if(row.praiseScore == null){
                                return "-"
                            } else{
                                if (row.praiseScore >= 0) {
                                    return `<div style="color:#71CE66;overflow: hidden;text-overflow: ellipsis">${row.praiseScore}</div>`;
                                } else if (row.praiseScore < 0) {
                                    return `<div style="color:#F99D5E;overflow: hidden;text-overflow: ellipsis">${row.praiseScore}</div>`;
                                } else {
                                    return "-";
                                }
                            }
                        },
                    },
                    {
                        label: "批评",
                        prop: "criticizeScore",
                        sortable: "custom",
                        minWidth: 120,
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            if(row.criticizeScore == null){
                                return "-"
                            } else {
                                if (row.criticizeScore >= 0) {
                                    return `<div style="color:#71CE66;overflow: hidden;text-overflow: ellipsis">${row.criticizeScore}</div>`;
                                } else if (row.criticizeScore < 0) {
                                    return `<div style="color:#F99D5E;overflow: hidden;text-overflow: ellipsis">${row.criticizeScore}</div>`;
                                } else {
                                    return "-";
                                }
                            }
                        },
                    },
                ],
                check: false,
                indexFixed: "left",
                height: "",
                isExpandAll: false,
                rowkey: "organId",
            },
            // 表格数据
            table_data: [],
            table_data_ori: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,
            stuLoadingTable: false,
            exportLoading: false,
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "1150px",
            },
            organName: '',  //组织名称
            organType: '',  //组织类型
            organId: '',  //组织ID
            dialogTableList: [],
            showViewer: false,
            viewPhoto: "",
            // 总分/表扬/批评
            stuTotal: 0,
            totalScoreHonour: 0,
            totalScorePunish: 0,
            //end
            objClassId: {},
            firstTime: true,
            schoolCalendar: [],
            moralmanageClassManageClassDataCheckPerson: false, // 弹窗是否含有检查人
            // 德育考核弹窗
            fileLists: [],
            isUpdataImg: true,
            isUpdataVideo:true,
            acceptValue:"image/*,video/mp4", //允许上传的类型
            acceptType:"", //记录上传的类型
            dialogObjDormitory: {
                title: "编辑考核",
                width: "auto",
                dialogVisible: false,
            },
             //宿舍考核相关
            isUpdataImg: true,
            isUpdataVideo:true,
            acceptValue:"image/*,video/mp4", //允许上传的类型
            acceptType:"", //记录上传的类型
            //德育考核、班级考核相关
            isUpdataImgs: true,
            isUpdataVideos:true,
            acceptValues:"image/*,video/mp4", //允许上传的类型
            acceptTypes:"", //记录上传的类型
            ruleFormDormitory: {
                schoolId: "",
                assessDate: "",
                ruleId: "",
                behaviorId: "",
                img: [],
                score: "",
                remark: "",
                ruleType: "1",
                type: "", // 考核范围
                stuIds: [],
            },
            dormitoryForm: {
                id:"",
                dormitoryId: "", // 楼栋id
                dormitoryName: "", // 楼栋名
                dormitoryFloorId: "", // 楼层id
                dormitoryFloorName: "", // 楼层名
                dormitoryRoomId: "", // 房间id
                dormitoryRoomName: "", // 房间名
            },
            rulesDormitory: {
                assessDate: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核日期",
                    },
                ],
                ruleType: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核类型",
                    },
                ],
                ruleId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核指标",
                    },
                ],
                ruleType: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择指标类型",
                    },
                ],
                behaviorId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核行为",
                    },
                ],
                score: [
                    {
                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                        message: "分值最多支持一位小数点",
                    },
                ],
            },
            updateId: "",  // 德育考核编辑的id
            ruleList: [],
            behaviorList: [], // 考核行为数据
            fileList: [],
            fileLists: [],
            treeOptions: [], // 指标列表
            // 德育考核弹窗
            dialogObjEduMoral: {
                title: "编辑考核",
                width: "668px",
                dialogVisible: false,
            },
            ruleFormEduMoral: {
                schoolId: "",
                classId: "",
                gradeId: "",
                onlyCode: "",
                studentId: "",
                assessDate: "",
                ruleId: "",
                behaviorId: "",
                img: [],
                score: "",
                remark: "",
                ruleType: "",
            },
            rulesEduMoral: {
                assessDate: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核日期",
                    },
                ],
                ruleId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核指标",
                    },
                ],
                ruleType: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择指标类型",
                    },
                ],
                behaviorId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核行为",
                    },
                ],
                score: [
                    {
                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                        message: "分值最多支持一位小数点",
                    },
                ],
            },
            ruleListEduMoral: [],
            behaviorListEduMoral: [],
            updatObj:{},
        }
    },
    created(){
        this.init()
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.firstTime) {
            this.initBaseData("activated");
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    computed:{
        ...mapState({
            schoolId: (state) => state.schoolId,
            permissions: (state) => state.permissions,
        }),
        exportUrl() {
            return require("@/assets/images/moralAsstessment/summary-icon.png");
        },
        addUrl() {
            return require("@/assets/images/moralAsstessment/honour-icon.png");
        },
        reduceUrl() {
            return require("@/assets/images/moralAsstessment/smile-down-icon.png");
        },
        // 下载模板
        downLoadTempBtn() {
            return ["dormitoryDistribution:downLoadTemp"];
        },
        isDisabled() {
            return !hasPermission(["moralmanage:classmanage:gradedata:detail"]);
        },
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
        formDataComputed() {
            if (this.formData.data.length < 3) {
                this.formData.btnList = this.formData.btnList.filter(i => i.text !== '重置')
            }
            return this.formData
        }
    },
    methods:{
        init() {
            this.initAssignment();
            this.initFilter();
            this.initTableConfig();
            this.initBaseData("created");
        },
        initAssignment() {
            this.firstTime = false;
            this.listQuery.schoolId = this.schoolId;
        },
        initFilter() {
            let moralmanageClassmanageClassdataDataRange =
                this.permissions.some((item) => {
                    return (
                        "moralmanage:classmanage:gradedata:dataRange" == item
                    );
                });
            if (!moralmanageClassmanageClassdataDataRange) return;
            let idx = 0,
                bol = false;
            let insertValue = {
                type: "select",
                label: "",
                value: "1",
                placeholder: "全部数据",
                key: "dataScope",
                list: [
                    {
                        label: "全部数据",
                        value: "1",
                    },
                    {
                        label: "不含本班考核数据",
                        value: "2",
                    },
                ],
            };
            this.formData.data.forEach((item, index) => {
                if (item.key == "organId") {
                    idx = index + 1;
                }

                if (item.key == "dataScope") {
                    bol = true;
                }
            });

            if (!bol) {
                this.formData.data.splice(idx, 0, insertValue);
                this.formData.closeWidth = 700;
                this.listQuery.dataScope = "1";
            }
        },
        initTableConfig() {
            this.moralmanageClassManageClassDataCheckPerson =
                this.permissions.some((item) => {
                    return (
                        "moralmanage:classmanage:gradedata:checkPerson" == item
                    );
                });
        },
        async initBaseData(key) {
            await this.getSchoolCalendar();
            await this.getOrageList();
            this.setDefaultDate();
            this.getList();
        },
        // 查询校历
        async getSchoolCalendar() {
            let params = {
                schoolId: this.schoolId,
                currentDate: this.$moment().format("YYYY-MM-DD HH:MM:SS"),
            };
            await this._fet(
                "/school/schoolCalendar/listByCondition",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.schoolCalendar = res.data.data;
                }
            });
        },
        // 设置默认查询
        setDefaultDate() {
            this.listQuery.assessDateStart = this.$moment(new Date())
                .subtract(7, "days")
                .format("YYYY-MM-DD");
            this.listQuery.assessDateEnd = this.$moment().format("YYYY-MM-DD");
            if (this.schoolCalendar.length > 0) {
                // 根据校历设置默认查询时间
                let {startTime, endTime} = this.schoolCalendar[0];
                startTime = this.formatDate(startTime);
                endTime = this.formatDate(endTime);
                if (
                    this.listQuery.assessDateEnd < endTime &&
                    this.listQuery.assessDateEnd > startTime
                ) {
                    this.listQuery.assessDateStart = startTime;
                }
            }
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.listQuery.assessDateStart,
                        this.listQuery.assessDateEnd,
                    ];
                }
            });
        },
        formatDate(date) {
            return date.replace(/[年月]/g, "-").replace(/[日]/, "");
        },
        // 获取组织列表
        async getOrageList() {
            const SManagerEduDeormitoryGradeData = new SManagerEduDeormitoryGradeDataModel()
            SManagerEduDeormitoryGradeData.getSchoolOrganizationTree({
                schoolId:this.schoolId,
                jobScope:["1","2","3"]
            }).then((res) => {
                if (res.data.code === '200') {
                    const index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    this.formData.data[index].list = formatTreeData(res.data.data.list, 'id', 'parentOrg')
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        // 获取年级量化列表数据
        getList(){
            this.loadingTable = true;
            const SManagerEduDeormitoryGradeData = new SManagerEduDeormitoryGradeDataModel()
            console.log(this.listQuery, 'listQuery');
            if((typeof this.listQuery.organId) == 'string' ){
                this.listQuery.organId = [this.listQuery.organId]
            }
            this.listQuery.organId = this.listQuery.organId ? this.listQuery.organId.join(',') : '';
            SManagerEduDeormitoryGradeData.getGradeDateRuleList(this.listQuery).then(res=>{
                if(res.data.code === '200'){
                    this.table_data = formatTreeData(res.data.data, 'organId', 'parentOrg')
                    this.table_data_ori = this._.cloneDeep(
                        this.table_data,
                    );
                    console.log(this.table_data, 'table_data');
                    // this.handleClick()
                }else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.loadingTable = false;
            })
            .catch(() => {
                this.loadingTable = false;
            });
        },
        handleClick(isAss) {
            //this.$refs.ctab.handleExpand(isAss)
            this.handleExpand(isAss);
        },
        handleExpand(isAss) {
            // 此判断是否为展开状态或折叠状态--若展开折叠是同一个按钮则无需传参和加此if
            this.table_config.isExpandAll = !this.table_config.isExpandAll;
            // this.tableData为返回的二级数据data
            this.$nextTick(() => {
                this.forArr(this.table_data, this.table_config.isExpandAll);
            });
        },
        // 遍历
        forArr(arr, isExpand) {
            arr.forEach((i) => {
                // toggleRowExpansion见element文档
                this.$refs.table.$refs.multipleTable.toggleRowExpansion(
                    i,
                    isExpand,
                );
                if (i.children) {
                    this.forArr(i.children, isExpand);
                }
            });
        },
        //  下载统计
        handleExport(){
            this.exportLoading = true;
            let form = {
                schoolId: this.listQuery.schoolId,
                organId: this.listQuery.organId,
                assessDateStart: this.listQuery.assessDateStart,
                assessDateEnd: this.listQuery.assessDateEnd,
                dataScope: this.listQuery.dataScope,
            }
            downloadFile({url: '/school/schoolMoralGradeDateRule/export', form}, ()=>{
                // this.$message.success("导出成功");
                this.exportLoading = false;
            },
            ()=>{
                this.exportLoading = false;
            })
        },
        // 点击查询/重置按钮
        clickBtn(data){
            switch (data.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === "dateArr") {
                            if (item.value && item.value.length > 0) {
                                this.listQuery.assessDateStart = item.value[0];
                                this.listQuery.assessDateEnd = item.value[1];
                            } else {
                                this.listQuery.assessDateStart = "";
                                this.listQuery.assessDateEnd = "";
                            }
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                    });
                    this.getList();
                    // this.handleClick()
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.setDefaultDate();
                    this.getList();
                    break;
                default:
                    break;
            }
        },
        formatter(treeData, prop, fn){
            treeData.map((item) => {
                if (item.children && item.children.length > 0) {
                    item.children = item.children.sort(fn);
                    this.formatter(item.children, prop, fn)
                }
            })
            return treeData
        },
        // 表格排序
        handleSortChange({column, order, prop}) {
            if (order === "ascending") {
                this.table_data = this.formatter(this.table_data, prop, (a,b) => a[prop] - b[prop])
                this.table_data = this.table_data.sort((a, b) => {
                    return a[prop] - b[prop];
                });
            } else if (order === "descending") {
                this.table_data = this.formatter(this.table_data, prop, (a,b) => b[prop] - a[prop])
                this.table_data = this.table_data.sort((a, b) => {
                    return b[prop] - a[prop];
                });
            } else {
                this.table_data = this._.cloneDeep(this.table_data_ori);
            }
        },
        /**
         * @Description: 点击查看德育考核记录
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 
         * @Date: 2024-06-14 16:19:35
         * @param {*} item
         */        
        linkClick(item) {
            this.dialogObj.dialogVisible = true;
            this.dialogObj.title = item.organName + "德育考核记录";
            this.organName = item.organName
            this.organType = item.organType;
            this.organId = item.organId;
            this.objClassId = item;
            const params = {
                schoolId: this.schoolId,
                assessDateStart:
                    this.listQuery.assessDateStart.concat(" 00:00"),
                assessDateEnd: this.listQuery.assessDateEnd.concat(" 23:59"),
                dataScope: this.listQuery.dataScope,
            };
            if(item.organType == 2){
                params.levelId = item.organId
            }else if(item.organType == 3){
                params.gradeId = item.organId
            }else if(item.organType == 4){
                params.classId = item.organId
            }
            const SManagerEduDeormitoryGradeData = new SManagerEduDeormitoryGradeDataModel()
            this.stuLoadingTable = true
            SManagerEduDeormitoryGradeData.getStuQuantizationDetail(params).then(res=>{
                this.stuLoadingTable = false
                if(res.data.code == '200'){
                    this.dialogTableList =
                        res.data.data.studentBehaviorList;
                    this.stuTotal = res.data.data.totalScore;
                    this.totalScoreHonour = res.data.data.totalScoreHonour;
                    this.totalScorePunish = res.data.data.totalScorePunish;
                    this.dialogTableList.forEach(iv=>{
                        if (iv.img) {
                            iv.img = iv.img.split(",");
                        } else {
                            iv.img = []
                        }
                    })
                }else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            })
        },
        // 关闭详情弹窗
        handleClose() {
            this.dialogObj.dialogVisible = false;
        },
        // 放大图片
        handleImg(data) {
            console.log(data, 'data');
            this.viewPhoto = data;
            this.showViewer = true;
        },
        handleCloseImg() {
            this.showViewer = false;
        },
        handleCloseVideo () {
            this.$refs.videoElement.pause();
            this.videoMovSrc = "";
            this.dialogMov.dialogVisible = false;
        },
        /**
         * @Description: 预览视频
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-04 15:55:59
         */
         handlePreview(data){
            this.videoMovSrc= "";
            console.log("预览视频",data);
            if (data.toLowerCase().indexOf('mov') > -1) {
                this.videoMovSrc = data;
                this.dialogMov.dialogVisible = true;
            } else {
                let videoType = ['swf', 'avi', 'flv', 'mpg', 'rm', 'mov', 'wav', 'asf', '3gp', 'mkv', 'rmvb', 'mp4']
                let url = data.split('/');
                url = url[url.length -1].split('.');
                url = url[url.length -1];

                if (data.indexOf('minio') !== -1 && videoType.indexOf(url) == -1) {
                    let checkFilePreview = filePreview();
                    checkFilePreview.openNewPage(data)
                } else {
                    let url = decodeURIComponent(data) // 要预览视频文件的访问地址
                    let Base64 = require("js-base64").Base64
                    let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(Base64.encode(url))}`
                    window.open(previewUrl)
                }
            }

		},
        /**
         * @Description: 编辑考核记录
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 
         * @Date: 2024-06-14 16:18:55
         * @param {*} data1
         */        
        handleEdit(data1){
            console.log('data1',data1);
            let data = this._.cloneDeep(data1)
            console.log("data", data);
            // 宿舍考核弹窗
            if (data.category == "1" || data.category == "3" ) {
                if(data.img.length > 0 && (data.img[0].toLowerCase().indexOf('mp4') > -1 || data.img[0].toLowerCase().indexOf('mov') > -1)){
                    this.isUpdataVideo = false
                }else if(data.img.length === 3) {
                    this.isUpdataImg = false
                }
                Object.keys(this.ruleFormDormitory).forEach((key) => {
                    this.ruleFormDormitory[key] = data[key];
                    this.ruleFormDormitory.category = "1";
                });
                let studArr = []
                studArr.push(data.studentId)
                this.ruleFormDormitory.stuIds = studArr;
                this.dormitoryForm.id = data.id;
                this.dormitoryForm.dormitoryId = data.dormitoryId;
                this.dormitoryForm.dormitoryName = data.dormitoryName;
                this.dormitoryForm.dormitoryFloorId = data.dormitoryFloorId;
                this.dormitoryForm.dormitoryFloorName = data.dormitoryFloorName;
                this.dormitoryForm.dormitoryRoomId = data.dormitoryRoomId;
                this.dormitoryForm.dormitoryRoomName = data.dormitoryRoomName;
                this.ruleFormDormitory.ruleId = data.ruleId
                this.ruleFormDormitory.behaviorId = data.behaviorId
                console.log('this.ruleFormDormitory.ruleId',this.ruleFormDormitory.ruleId,data.ruleId);
                this.getRuleList(this.ruleFormDormitory.ruleId,this.ruleFormDormitory.behaviorId);
                this.dialogObjDormitory.dialogVisible = true;
            } else {
                if (data.img.length > 0 && (data.img[0].toLowerCase().indexOf('mp4') > -1 || data.img[0].toLowerCase().indexOf('mov') > -1)) {
                    this.isUpdataVideo = false
                } else if(data.img.length === 3) {
                    this.isUpdataImg = false
                }
                // 德育考核弹窗
                const keyList = [
                    "studentId",
                    "gradeId",
                    "classId",
                    "onlyCode",
                    "ruleId",
                    "score",
                    "remark",
                    "ruleType",
                    'assessDate'
                ];
                keyList.forEach((item) => {
                    this.ruleFormEduMoral[item] = data[item];
                });
                console.log('this.ruleFormEduMoral',this.ruleFormEduMoral);
                this.ruleFormEduMoral.classId = data.classId
                this.ruleFormEduMoral.className = data.className
                this.ruleFormEduMoral.ruleType = data.ruleType
                this.ruleFormEduMoral.ruleId = data.ruleId
                this.ruleFormEduMoral.schoolId = this.schoolId;
                this.ruleFormEduMoral.studentId = data.studentId;
                this.updateId = data.id
                this.ruleFormEduMoral.img = data.img
                this.getEduMoralRuleList(this.ruleFormEduMoral.ruleId,data.behaviorId);
                this.dialogObjEduMoral.dialogVisible = true;
            }
        },
        /**
         * @Description:根据考核类型筛选考核指标数据 
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 
         * @Date: 2024-06-14 16:18:03
         * @param {*} data
         * @param {*} findKey
         * @param {*} findVal
         * @param {*} subarrKey
         */        
        treeFilter(data, findKey, findVal, subarrKey) {
            if (findVal) {
                let newData = data.filter((x) => x[findKey] === findVal);
                newData.forEach(
                    (x) =>
                        x[subarrKey] &&
                        (x[subarrKey] = this.treeFilter(
                            x[subarrKey],
                            findKey,
                            findVal,
                            subarrKey,
                        )),
                );
                return newData;
            } else {
                return data;
            }
        },
        /**
         * @Description: 获取宿舍考核指标列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-26 14:28:55
         */
        getRuleList(data,itedata) {
            const sManagerEduDeormitoryDataModule =
                new SManagerEduDeormitoryDataModule();
            sManagerEduDeormitoryDataModule
                .dormitoryRoomRule({
                    schoolId: this.schoolId,
                    category: "1",
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        console.log(
                            "宿舍考核指标列表res.data.data",
                            res.data.data,
                        );
                        this.ruleList = formatTreeData(
                            res.data.data,
                            "id",
                            "parentId",
                        );
                        this.treeOptions = this.ruleList
                        this.ruleFormDormitory.ruleId = (res.data.data.find((i) => i.id === data))?(res.data.data.find((i) => i.id === data).id):"";
                        console.log('获取宿舍考核指标的this.ruleFormDormitory.ruleId',this.ruleFormDormitory.ruleId);
                        this.$nextTick(()=>{
                            this.$refs.formRef.clearValidate();
                        })
                        this.changeRule(data,itedata)
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 选择宿舍考核指标，获取考核行为数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:04
         * @param {*} data
         */
        changeRule(data,itedata) {
            this.behaviorList = [];
            // this.ruleFormDormitory.ruleType = "";
            this.ruleFormDormitory.behaviorId = "";
            this.ruleFormDormitory.score = "";
            const current = treeFind(this.ruleList, (x) => x.id === data);
            console.log(this.ruleList,'this.ruleList==========',current);
            if (current) {
                this.ruleFormDormitory.ruleType = current.ruleType;
                this.behaviorList = current.behaviorList;
                this.ruleFormDormitory.behaviorId = (this.behaviorList.find((i) => i.id === itedata))?(this.behaviorList.find((i) => i.id === itedata).id):'';
                this.ruleFormDormitory.score = (this.behaviorList.find((i) => i.id === itedata))?(this.behaviorList.find((i) => i.id === itedata).score):'';
            }
        },
        /**
         * @Description: 改变考核行为
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 
         * @Date: 2024-06-14 16:17:21
         * @param {*} data
         */        
        changeBehavior(data) {
            let find = this.behaviorList.find((i) => i.id === data);
            if (find) {
                this.ruleFormDormitory.score = find.score;
            } else {
                this.ruleFormDormitory.score = "";
            }
        },
        /**
         * @Description: 改变宿舍考核类型
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 
         * @Date: 2024-06-14 16:16:56
         * @param {*} e
         */        
        changeDormiType(e){
            this.ruleFormDormitory.ruleId = "";
            this.ruleFormDormitory.behaviorId = "";
			this.ruleFormDormitory.score = "";
			this.ruleFormDormitory.ruleType = e;
            this.ruleFormDormitory.behaviorId = '';
        },
        /**
         * @Description: 修改考核类型
         * @DoWhat: 清空原本的表单相关数据，并修改考核类型
         * @UseScenarios: 
         * @Attention: 
         * @Author: 
         * @Date: 2024-06-14 16:13:57
         * @param {*} e
         */        
        changeRuleType(e){
            this.ruleFormEduMoral.ruleName = "";
			this.ruleFormEduMoral.behaviorName = "";
            this.ruleFormEduMoral.ruleId = "";
            this.ruleFormEduMoral.behaviorIds = [];
            this.ruleFormEduMoral.behaviorId = '';
			this.ruleFormEduMoral.score = "";
			this.ruleFormEduMoral.ruleType = e;
        },
        /**
         * @Description: 删除图片
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 
         * @Date: 2024-06-14 16:15:28
         * @param {*} index
         */        
        deleteSwiperImg(index) {
            this.fileList.splice(index, 1)
            this.ruleFormDormitory.img.splice(index, 1)
            console.log("打印删除后的list",this.ruleFormDormitory.img.length, this.acceptType)
            //如果第一个是图片，后面只能选图片
            if (this.ruleFormDormitory.img.length > 1 || (this.ruleFormDormitory.img.length === 1 &&  this.acceptType === 'image')) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
            } else {
                this.acceptValue = "image/*,video/mp4"
                this.acceptType = ""
            }
            if (this.ruleFormDormitory.img.length ===0){
                this.isUpdataVideo = true
                this.isUpdataImg = true
            }else if(this.ruleFormDormitory.img.length < 3){
                this.isUpdataImg = true
            }
            console.log( this.acceptValue,this.isUpdataImg,this.isUpdataVideo,'可以上传的类型');
        },
        /**
         * @Description: 上传校验
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:26
         * @param {*} file
         */
        beforeUpload(file) {
            console.log("file上传文件的类型", file)
            if(this.ruleFormDormitory.img.length >=1 && this.acceptType ==='image'){
                console.log('进来了吗');
                if (file.type === "video/mp4") {
                    this.$message.error("只允许上传图片!");
                    this.acceptType ==='image'
                    return false;
                }
            }
            //如果上传的是图片格式-限制上传3张
            const isImage = file.type.split("/")[0] === "image";
            const isLt20M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            //如果上传的是视频格式-限制上传1个视频大小在100M以内
            const isVideo = file.type === "video/mp4";
            const isLt100M = file.size / 1024 / 1024 < 100;
            if (isImage) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                if (!is50) {
                    this.$message.error("上传图片名称不能超过50个字符!");
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过 20MB!");
                    return false;
                }
            } else if (isVideo) {
                this.acceptType = "video"
                this.acceptValue = "video/mp4"
                if (!isLt100M) {
                    this.$message.error("视频超过系统限制请编辑后上传");
                    this.acceptValue = "image/*,video/mp4"
                    return false;
                }
               // 校验上传视频的时长不能超过20S
                return new Promise((resolve, reject) => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                let _this = this;
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(video.src);
                    let duration = video.duration;
                    //alert('视频时长为：' + duration + '秒');
                    if (duration > 20) {
                        _this.$message.error('视频超过系统限制请编辑后上传');
                        _this.acceptValue = "image/*,video/mp4"
                        reject('视频超过系统限制请编辑后上传');
                    } else {
                        resolve(true);
                    }
                }
                video.onerror = function() {
                    reject('无法获取视频时长');
                }
                video.src = URL.createObjectURL(file);
                });
            } else if(this.ruleFormDormitory.img.length >=1) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                this.$message.error("只允许上传图片!");
                return false;
            }else {
                this.acceptType = ""
                this.acceptValue = "image/*,video/mp4"
                this.$message.error("只允许上传图片 或 视频!");
                return false;
            }
           console.log("上传后的类型", this.acceptType)
        },
        /**
         * @Description: 上传校验
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:26
         * @param {*} file
         */
        beforeUpload(file) {
            console.log("file上传文件的类型", file)
            if(this.ruleFormDormitory.img.length >=1 && this.acceptType ==='image'){
                console.log('进来了吗');
                if (file.type === "video/mp4") {
                    this.$message.error("只允许上传图片!");
                    this.acceptType ==='image'
                    return false;
                }
            }
            //如果上传的是图片格式-限制上传3张
            const isImage = file.type.split("/")[0] === "image";
            const isLt20M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            //如果上传的是视频格式-限制上传1个视频大小在100M以内
            const isVideo = file.type === "video/mp4";
            const isLt100M = file.size / 1024 / 1024 < 100;
            if (isImage) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                if (!is50) {
                    this.$message.error("上传图片名称不能超过50个字符!");
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过 20MB!");
                    return false;
                }
            } else if (isVideo) {
                this.acceptType = "video"
                this.acceptValue = "video/mp4"
                if (!isLt100M) {
                    this.$message.error("视频超过系统限制请编辑后上传");
                    this.acceptValue = "image/*,video/mp4"
                    return false;
                }
               // 校验上传视频的时长不能超过20S
                return new Promise((resolve, reject) => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                let _this = this;
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(video.src);
                    let duration = video.duration;
                    //alert('视频时长为：' + duration + '秒');
                    if (duration > 20) {
                        _this.$message.error('视频超过系统限制请编辑后上传');
                        _this.acceptValue = "image/*,video/mp4"
                        reject('视频超过系统限制请编辑后上传');
                    } else {
                        resolve(true);
                    }
                }
                video.onerror = function() {
                    reject('无法获取视频时长');
                }
                video.src = URL.createObjectURL(file);
                });
            } else if(this.ruleFormDormitory.img.length >=1) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                this.$message.error("只允许上传图片!");
                return false;
            }else {
                this.acceptType = ""
                this.acceptValue = "image/*,video/mp4"
                this.$message.error("只允许上传图片 或 视频!");
                return false;
            }
           console.log("上传后的类型", this.acceptType)
        },
        /**
         * @Description: 上传功能回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:43
         * @param {*} res
         * @param {*} file
         * @param {*} fileList
         */
        handleSuccess(res, file, fileList) {
            if (res.code === "200") {
                this.ruleFormDormitory.img.push(res.data.url);
                if(this.ruleFormDormitory.img.length>=3){
                    this.isUpdataImg = false
                }
                if (this.ruleFormDormitory.img.length>= 1 && this.acceptType === "video") {
                    this.isUpdataVideo = false
                }
            }
        },
        /**
         * @Description: 添加考核行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:59
         */
        handleSave() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    let form = JSON.parse(JSON.stringify(this.ruleFormDormitory));
                    console.log('this.ruleFormDormitory.ruleId',this.ruleFormDormitory.ruleId);
                    form.img = form.img.toString();
                    console.log('form.ruleId',form.ruleId);
                    form.ruleId = this.ruleFormDormitory.ruleId
                    console.log(' form.ruleId', form.ruleId);
                    // console.log('form',form.img);
                    let formObj = {
                        ...this.dormitoryForm,
                        ...form,
                    };
                    console.log("formObj", formObj);
                    const sManagerEduDeormitoryDataModule =
                        new SManagerEduDeormitoryDataModule();
                    sManagerEduDeormitoryDataModule
                        .updateDormitoryRoomDetailInfo(formObj)
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("编辑考核成功");
                                this.handleCloseDormitory();
                                this.getList();
                                this.linkClick(this.objClassId);
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(() => {});
                }
            });
        },
        /**
         * @Description: 关闭宿舍考核行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:21:09
         */
        handleCloseDormitory() {
            this.isUpdataImg = true
            this.isUpdataVideo = true
            this.ruleFormDormitory = this.$options.data.call().ruleFormDormitory;
            this.behaviorList = [];
            this.$nextTick(() => {
                this.$refs.formRef.clearValidate();
            });
            this.dialogObjDormitory.dialogVisible = false;
            // this.linkClick(this.objClassId);
            this.getList();
        },
        // 德育考核相关
        getEduMoralRuleList(data,itemData) {
            this._fet("/school/schoolMoralRule/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.ruleListEduMoral = formatTreeData(
                        res.data.data,
                        "id",
                        "parentId",
                    );
                    this.ruleFormEduMoral.ruleId = (res.data.data.find((i) => i.id === data))?(res.data.data.find((i) => i.id === data).id):"";
                    this.$nextTick(() => {
                        this.$refs.formRefEduMoral.clearValidate();
                    });
                    this.changeRuleEduMoral(data,itemData)
                }
            });
        },
        // 选择考核指标，获取考核行为数据
        changeRuleEduMoral(data,itemData) {
            this.behaviorListEduMoral = [];
            this.ruleFormEduMoral.ruleType = "";
            this.ruleFormEduMoral.behaviorId = "";
            this.ruleFormEduMoral.score = "";
            const current = treeFind(this.ruleListEduMoral, (x) => x.id === data);
            if (current) {
                this.ruleFormEduMoral.ruleType = current.ruleType;
                this.behaviorListEduMoral = current.behaviorList;
            }
            this.ruleFormEduMoral.behaviorId = (this.behaviorListEduMoral.find((i) => i.id === itemData))?(this.behaviorListEduMoral.find((i) => i.id === itemData).id):'';
            this.ruleFormEduMoral.score = (this.behaviorListEduMoral.find((i) => i.id === itemData))?(this.behaviorListEduMoral.find((i) => i.id === itemData).score):'';
        },
        changeBehaviorEduMoral(data) {
            let find = this.behaviorListEduMoral.find((i) => i.id === data);
            if (find) {
                this.ruleFormEduMoral.score = find.score;
            } else {
                this.ruleFormEduMoral.score = "";
            }
        },
        //限制传文件个数
        handleExceed() {
            // this.$message.warning("最多选择 3 张图片");
            console.log(this.acceptType,'上传的类型')
            if (this.acceptType == "image"){
                this.$message.warning("最多选择 3 张图片");
            } else if (this.acceptType == "video") {
                this.$message.warning("最多选择 1 个视频");
            } else {
                this.$message.warning("最多选择 3 张图片 或 1 个视频");
            }
        },
        // 上传校验
        beforeUploadEduMoral(file) {
            console.log("file上传文件的类型", file)
            if(this.ruleFormEduMoral.img.length >=1 && this.acceptType ==='image'){
                console.log('进来了吗');
                if (file.type === "video/mp4") {
                    this.$message.error("只允许上传图片!");
                    this.acceptType ==='image'
                    return false;
                }
            }
            //如果上传的是图片格式-限制上传3张
            const isImage = file.type.split("/")[0] === "image";
            const isLt20M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            //如果上传的是视频格式-限制上传1个视频大小在100M以内
            const isVideo = file.type === "video/mp4";
            const isLt100M = file.size / 1024 / 1024 < 100;
            if (isImage) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                if (!is50) {
                    this.$message.error("上传图片名称不能超过50个字符!");
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过 20MB!");
                    return false;
                }
            } else if (isVideo) {
                this.acceptType = "video"
                this.acceptValue = "video/mp4"
                if (!isLt100M) {
                    this.$message.error("视频超过系统限制请编辑后上传");
                    this.acceptValue = "image/*,video/mp4"
                    return false;
                }
               // 校验上传视频的时长不能超过20S
                return new Promise((resolve, reject) => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                let _this = this;
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(video.src);
                    let duration = video.duration;
                    //alert('视频时长为：' + duration + '秒');
                    if(duration > 20) {
                        _this.$message.error('视频超过系统限制请编辑后上传');
                        _this.acceptValue = "image/*,video/mp4"
                    reject('视频超过系统限制请编辑后上传');
                    } else {
                    resolve(true);
                    }
                }
                video.onerror = function() {
                    reject('无法获取视频时长');
                }
                video.src = URL.createObjectURL(file);
                });
            } else if(this.ruleFormEduMoral.img.length >=1) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                this.$message.error("只允许上传图片!");
                return false;
            } else {
                this.acceptType = ""
                this.acceptValue = "image/*,video/mp4"
                this.$message.error("只允许上传图片 或 视频!");
                return false;
            }
           console.log("上传后的类型", this.acceptType)
        },
        // 上传功能回调
        handleSuccessEduMoral(res, file, fileList) {
            if (res.code === "200") {
                this.ruleFormEduMoral.img.push(res.data.url);
                if(this.ruleFormEduMoral.img.length >= 3){
                    this.isUpdataImg = false
                }
                if (this.ruleFormEduMoral.img.length>= 1 && this.acceptType === "video") {
                    this.isUpdataVideo = false
                }
            }
        },
        // 删除某一张图片
        deleteSwiperImgEduMoral(index) {
            this.fileLists.splice(index, 1);
            this.ruleFormEduMoral.img.splice(index, 1);
            //如果第一个是图片，后面只能选图片
            if (this.ruleFormEduMoral.img.length > 1 || (this.ruleFormEduMoral.img.length === 1 &&  this.acceptType === 'image')) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
            } else {
                this.acceptValue = "image/*,video/mp4"
                this.acceptType = ""
            }
            if (this.ruleFormEduMoral.img.length < 3) {
                this.isUpdataImg = true;
            }
            if (this.ruleFormEduMoral.img.length < 1){
                this.isUpdataVideo = true
            }

        },
        // 编辑考核行为
        handleSaveEduMoral(){
            if (
                !this.ruleFormEduMoral.gradeId ||
                !this.ruleFormEduMoral.classId
            ) {
                this.$message.warning(
                    "该学生缺少年级班级信息，请补充后再添加考核",
                );
                return;
            }
            let updateForm = JSON.parse(JSON.stringify(this.ruleFormEduMoral))
            updateForm.img = updateForm.img.toString()
            updateForm.ruleId = this.ruleFormEduMoral.ruleId
            updateForm.id = this.updateId
            console.log('updateForm',updateForm)
            this.$refs.formRefEduMoral.validate((val) => {
                if (val) {
                    this._fet(
                        "/school/schoolMoralStudentDetail/updateStudentMoral",
                        updateForm,
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("编辑考核成功");
                                this.handleCloseEduMoral();
                                this.setDefaultDate();
                                this.getList();
                                this.linkClick(this.objClassId);
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(() => {});
                }
            });
        },
        // 关闭添加考核行为弹窗
        handleCloseEduMoral() {
            this.isUpdataImg = true
            this.isUpdataVideo = true
            this.ruleFormEduMoral = this.$options.data.call().ruleFormEduMoral;
            console.log('关闭的this.ruleFormEduMoral',this.ruleFormEduMoral);
            this.behaviorListEduMoral = [];
            this.$nextTick(() => {
                this.$refs.formRefEduMoral.clearValidate();
            });
            this.dialogObjEduMoral.dialogVisible = false;
            // this.linkClick(this.objClassId);
            this.getList();
        },
        // 删除
        handleDelete(data) {
            this.$confirm("确定要删除这条记录吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 发请求
                    this._fet(
                        "/school/schoolMoralStudentDetail/deleteStuDetail",
                        {id: data.id},
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("删除成功");
                            this.linkClick(this.objClassId);
                            this.getList();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        // 下载详情
        handleSubmit(){
            const params = {
                schoolId: this.schoolId,
                assessDateStart:
                    this.listQuery.assessDateStart.concat(" 00:00"),
                assessDateEnd: this.listQuery.assessDateEnd.concat(" 23:59"),
                dataScope: this.listQuery.dataScope,
                remark: this.organName
            };
            if(this.organType == 2){
                params.levelId = this.organId
            }else if(this.organType == 3){
                params.gradeId = this.organId
            }else if(this.organType == 4){
                params.classId = this.organId
            }
            downloadFile(
                {
                    url: "/school/schoolMoralGradeDateRule/stuDetailListExport",
                    form: params,
                },
                () => {
                    this.$message.success("导出成功（暂不支持视频下载）");
                },
                () => {},
            );
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
.top_row {
    border: 1px solid #ebeef5;
    border-bottom: none;
    height: 40px;
    display: flex;
    // align-items: center;
    padding-left: 10px;
    line-height: 40px;

    .score_img {
        margin-top: 2px;
        margin-left: 20px;
    }

    .all_score {
        margin-left: 7px;
    }

    .add_score {
        color: #f99d5e;
        margin-left: 4px;
    }

    .reduce_score {
        color: #71ce66;
        margin-left: 4px;
    }
}
.add {
    color: #f99d5e;
}
.jian {
    color: #71ce66;
}
.disFlex {
    display: flex;
}
.btn_edit {
    color: #3c7fff;
    cursor: pointer;
    margin-right: 10px;
}
.btn_delet {
    color: #e63a2e;
    cursor: pointer;
}
.img2 {
    width: 60px;
    height: 45px;
    border-radius: 8px;
    cursor: pointer;
}

::v-deep .el-table {
    /* .el-table__row .el-table__cell:first-child .cell {
        padding-left: 62px;
    } */

    [class*=el-table__row--level] {
        .el-table__expand-icon {
            margin-right: 4px;

            &:hover .el-icon-arrow-right {
                background-color: #3c7fff;
            }
        }

        .el-table__expand-icon .el-icon-arrow-right {
            transform: rotate(90deg);
            color: #FFFFFF;
            font-size: 13px;
            width: 16px;
            height: 16px;
            line-height: 17px;
            text-align: center;
            background-color: #bfbfbf;
            border-radius: 50%;
            transition: rotate 0.2s ease-in;
        }

        .el-table__expand-icon--expanded .el-icon-arrow-right {
            transform: rotate(-180deg) !important;
            background-color: #3c7fff;
        }

        .el-table__placeholder {
            width: 0;
        }
    }
}
.name-header-slot {
    display: flex;
    align-items: center;

    .expand-all {
        display: flex;
        align-items: center;
        margin-right: 16px;
        height: 23px;
        padding: 0;

        .el-icon-arrow-down {
            width: 16px;
            height: 16px;
            line-height: 18px;
            text-align: center;
            font-size: 13px;
            border-radius: 50%;
            color: #FFFFFF;
            background-color: #bfbfbf;
            transition: rotate 0.2s ease-in;
        }

        span {
            margin-left: 5px;
            font-size: 14px;
            color: #595959;
            font-family: Microsoft YaHei;
        }

        &:hover {
            span {
                color: #3c7fff;
            }

            .el-icon-arrow-down {
                color: #ffffff;
                background-color: #3c7fff;
            }
        }
    }

    .active {
        .el-icon-arrow-down {
            transform: rotate(-180deg);
            background-color: #3c7fff;
        }

        span {
            color: #3c7fff;
        }
    }
}
::v-deep .el-button.is-disabled.el-button--text {
    color: #363b40;
}
.swiper-wrap {
    display: flex;
    flex-wrap: wrap;
}
.img_each {
    width: 80px;
    height: 80px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    border: 1px solid #c0ccda;
    margin-right: 10px;

    img {
        object-fit: cover;
    }

    .del {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        opacity: 0;

        &:hover {
            opacity: 1;
        }

        .el-icon-delete {
            font-size: 20px;
            color: #ffffff;
        }
    }
}
.upload-wrap {
    display: flex;
    align-items: center;
}

.upload-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 16px;

    .add-icon {
        margin-top: 24px;
        font-size: 20px;
        line-height: 20px;
        color: #c2c8cc;
    }

    .upload-text {
        margin-top: 15px;
        line-height: 12px;
        font-size: 12px;
        color: #999999;
    }
}
// 图片数量等于限制数量时隐藏上传组件
::v-deep.hide {
    .el-upload--avatar-uploader {
        display: none !important;
    }
}
.tipText{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #BBBDBF;
}
.dialog-form-video{
    ::v-deep .el-dialog {
        width: 700px !important;
        height: 600px!important;
    }
}
.simple-dialog-form {

    .behavior-select {
        ::v-deep .el-select-dropdown__item{
            width: 452px;
        }
    }
}
</style>
